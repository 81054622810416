/**
 * @module utils
 */
/** comment to work-around limitation of typedoc module plugin */

// Copyright 2018-2024 Enlightware GmbH, Switzerland

// eslint-disable-next-line no-underscore-dangle
declare const __CODE_VERSION__: string;
export const CodeVersion = __CODE_VERSION__;
// eslint-disable-next-line no-underscore-dangle
declare const __RUST_VERSION__: string;
export const RustVersion = __RUST_VERSION__;
// eslint-disable-next-line no-underscore-dangle
declare const __BUILD_FLAGS__: string;
export const BuildFlags = __BUILD_FLAGS__;

export const UserVersion = 'alpha 1';

export function showVersion(backendVersion?: string) {
	const serverVersion = backendVersion !== undefined ? backendVersion : 'unknown backend';
	const buildFlags = __BUILD_FLAGS__.length !== 0 ? `, ${__BUILD_FLAGS__}` : '';
	console.info(`Version ${UserVersion}. Build ${CodeVersion} (${RustVersion}${buildFlags}), ${serverVersion}.`);
	// const userText = `<span class="userVersion">${UserVersion}</span><br/><span class="codeVersion">${CodeVersion}</span>`;
	const userText = `<span class="codeVersion">${CodeVersion}</span>`;
	for (const element of document.getElementsByClassName('version-userText')) {
		element.innerHTML = userText;
	}
	const fullText = `Candli ver. ${UserVersion} (build ${CodeVersion}${buildFlags}, ${serverVersion})`;
	for (const element of document.getElementsByClassName('version-fullText')) {
		element.innerHTML = fullText;
	}
}
