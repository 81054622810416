/**
 * The runtime part of the l10n
 * @module translator
 * @preferred
 */
/** comment to work-around limitation of typedoc module plugin */

// Copyright 2018-2024 Enlightware GmbH, Switzerland

import { defined, dynamicCast, toStr } from 'utils/types';

interface FormattableString {
	args(...args: any[]): string;
}

type TranslationVariants = [string, string][];
export type TranslationDictionary = Map<string, TranslationVariants>;
export type IntlTranslations = Map<string, TranslationDictionary>;

let lang = 'en';
let dictionary: TranslationDictionary | null = null;

function setTranslations(newDictionary: TranslationDictionary) {
	dictionary = newDictionary;
}

export function addTranslations(translations: IntlTranslations) {
	const newDictionary = translations.get(lang);
	if (newDictionary !== undefined) {
		if (dictionary === null) {
			dictionary = newDictionary;
		} else {
			dictionary = new Map([...newDictionary, ...dictionary]);
		}
	} else {
		if (lang !== 'en') {
			console.warn(`Language "${lang}" not found in provided translations!`);
		}
	}
}

function getVariant(variants: TranslationVariants, disambiguation?: string) {
	if (disambiguation !== undefined) {
		for (const [variant, translation] of variants) {
			if (variant === disambiguation) {
				return translation;
			}
		}
	}
	return defined(variants[0])[1];
}

export function qsTr(s: string, disambiguation?: string): FormattableString {
	const variants = dictionary?.get(s);
	if (variants !== undefined) {
		return new TranslatedString(getVariant(variants, disambiguation));
	} else {
		return new TranslatedString(s);
	}
}

export function qsT(s: string, disambiguation?: string): string {
	const variants = dictionary?.get(s);
	if (variants !== undefined) {
		return getVariant(variants, disambiguation);
	} else {
		return s;
	}
}

export function capitalise(s: string): string {
	if (s.length === 0) {
		return '';
	}
	return s.charAt(0).toUpperCase() + s.slice(1);
}

export class TranslatedString implements FormattableString {
	constructor(private translatedString: string) { }
	args(...args: any[]): string {
		let s = this.translatedString;
		let index = 1;
		for (const arg of args) {
			const pattern = `%${index}`;
			s = s.replaceAll(pattern, toStr(arg));
			index += 1;
		}
		return s;
	}
	toString() {
		return this.translatedString;
	}
}

function translateDOM(dict: TranslationDictionary) {
	const translateNode = (node: Node, doTranslate = true) => {
		const elementNode = dynamicCast(Element, node);
		if (elementNode !== null) {
			for (const attr of elementNode.attributes) {
				if (attr.name === 'title' && doTranslate) {
					const key = attr.value;
					const translation = dict.get(key);
					if (translation !== undefined) {
						attr.value = defined(translation[0])[1];
					} else {
						console.debug(`No translation found for title "${key}"`);
					}
				}
				if (attr.name === 'translate') {
					doTranslate = attr.value !== 'no';
				}
			}
		}
		const textNode = dynamicCast(Text, node);
		if (textNode !== null) {
			if (!doTranslate) {
				return;
			}
			const key = textNode.data;
			const trimmedKey = key.trim();
			if (trimmedKey.length === 0) {
				return;
			}
			const translation = dict.get(key);
			if (translation !== undefined) {
				textNode.data = defined(translation[0])[1];
			} else {
				console.debug(`No translation found for text "${key}"`);
			}
		} else {
			for (const child of node.childNodes) {
				translateNode(child, doTranslate);
			}
		}
	};
	translateNode(document.getRootNode());
}

export type TranslationPairs = Array<[string, string] | [string, string, string]>;

export function translationJsonToDictionary(pairs: TranslationPairs) {
	const dict: TranslationDictionary = new Map();
	for (const [key, translation, disambiguation] of pairs) {
		const disambiguationText = disambiguation ?? '';
		const entry = dict.get(key) ?? [];
		entry.push([disambiguationText, translation]);
		entry.sort((a, b) => a[0].localeCompare(b[0]));
		dict.set(key, entry);
	}
	return dict;
}

import TranslationFrHTML from '../../translations/translation-html-fr.json';
import TranslationDeHTML from '../../translations/translation-html-de.json';
import TranslationPtHTML from '../../translations/translation-html-pt.json';
import TranslationThHTML from '../../translations/translation-html-th.json';
const TranslationsHTML: IntlTranslations = new Map([
	['fr', translationJsonToDictionary(TranslationFrHTML as TranslationPairs)],
	['de', translationJsonToDictionary(TranslationDeHTML as TranslationPairs)],
	['pt', translationJsonToDictionary(TranslationPtHTML as TranslationPairs)],
	['th', translationJsonToDictionary(TranslationThHTML as TranslationPairs)]
]);

import TranslationFrTS from '../../translations/translation-typescript-fr.json';
import TranslationDeTS from '../../translations/translation-typescript-de.json';
import TranslationPtTS from '../../translations/translation-typescript-pt.json';
import TranslationThTS from '../../translations/translation-typescript-th.json';
import { Parameters } from 'apps/common/parameters';
const TranslationsTS: IntlTranslations = new Map([
	['fr', translationJsonToDictionary(TranslationFrTS as TranslationPairs)],
	['de', translationJsonToDictionary(TranslationDeTS as TranslationPairs)],
	['pt', translationJsonToDictionary(TranslationPtTS as TranslationPairs)],
	['th', translationJsonToDictionary(TranslationThTS as TranslationPairs)]
]);

export function loadBrowserLanguage() {
	const newLang = Parameters.language;
	console.debug(`Detected language "${newLang}" from localStorage, URL search params or browser language.`);
	if (newLang === 'en') {
		console.debug('Not loading translations for English!');
		return;
	}
	const htmlTranslation = TranslationsHTML.get(newLang);
	const tsTranslation = TranslationsTS.get(newLang);
	if (htmlTranslation !== undefined && tsTranslation !== undefined) {
		translateDOM(htmlTranslation);
		setTranslations(tsTranslation);
		document.documentElement.setAttribute('lang', newLang);
		lang = newLang;
	} else {
		if (htmlTranslation === undefined) {
			console.warn(`No HTML translation found for language "${newLang}", not changing language.`);
		}
		if (tsTranslation === undefined) {
			console.warn(`No Typescript translation found for language "${newLang}", not changing language.`);
		}
	}
}

export function getLanguage() {
	return lang;
}