/**
 * @module utils
 */
/** comment to work-around limitation of typedoc module plugin */

// Copyright 2018-2024 Enlightware GmbH, Switzerland

import { FeatureName } from './parameters';
import { OrganisationRole } from 'network/api-types';

const FeatureRoles: [FeatureName, (OrganisationRole | null)[]][] = [
	['canManageUsers', ['SchoolOwner', 'Teacher']],
	['canShop', ['SchoolOwner', 'HomeOwner', null]],
	['teacher', ['Teacher', 'SchoolOwner']],
];
const FeatureRoleMap: ReadonlyMap<FeatureName, readonly (OrganisationRole | null)[]> = new Map(FeatureRoles);

export function roleProvidesFeature(role: OrganisationRole | null, feature: FeatureName) {
	return FeatureRoleMap.get(feature)?.includes(role) ?? false;
}
