/**
 * @module utils
 */
/** comment to work-around limitation of typedoc module plugin */

// Copyright 2018-2024 Enlightware GmbH, Switzerland

import { integer } from './types';

export function toShortInteger(value: number) {
	integer(value);
	if (value < 1000) {
		return value.toString();
	} else if (value < 1000000) {
		const flooredValue = Math.trunc(value / 100) / 10;
		return flooredValue.toString() + ' k';
	} else {
		const flooredValue = Math.trunc(value / 100000) / 10;
		return flooredValue.toString() + ' M';
	}
}

export function humanDuration(seconds: number) {
	if (seconds <= 0) {
		return '0 s';
	} else if (seconds < 10) {
		return `${seconds.toFixed(1)} s`;
	} else if (seconds < 60) {
		return `${seconds.toFixed()} s`;
	} else if (seconds < 3600) {
		return `${(seconds / 60).toFixed()} min`;
	} else {
		return `${(seconds / 3600).toFixed()} h`;
	}
}

function makeCircularReplacer(): (key: string, value: any) => any {
	const seen = new WeakSet<any>();
	return (_key: string, value: any): any => {
		if (typeof value === 'object' && value !== null) {
			if (seen.has(value)) {
				return;
			}
			seen.add(value);
		}
		return value;
	};
}

export function robustStringify(value: any) {
	return JSON.stringify(value, makeCircularReplacer());
}

/// Returns true if the given string is a valid identifier, with Uniicode support.
/// A valid identifier must start with a letter or underscore, and can contain letters, digits, and underscores.
export function isValidIdentifier(identifier: string) {
	// Note: 'u' flag enables Unicode support in the regex
	const regex = /^[\p{L}_][\p{L}\p{N}_]*$/u;
	return regex.test(identifier);
}