/**
 * @module utils
 */
/** comment to work-around limitation of typedoc module plugin */

// Copyright 2018-2024 Enlightware GmbH, Switzerland

import { Product, ProductWithLicenceId } from 'network/api-types';
import { FeatureName } from './parameters';

const ProductFeatures: [Product, FeatureName[]][] = [
	['HomePremium', ['paidBasic']],
	['Classroom', ['paidBasic', 'assumeOnboarded']],
];
const ProductFeaturesMap: ReadonlyMap<Product, readonly FeatureName[]> = new Map(ProductFeatures);

export function latestFeatureExpiration(products: ProductWithLicenceId[], feature: FeatureName) {
	let delta = Number.NEGATIVE_INFINITY;
	for (const { product, expiresInSecs } of products) {
		if (ProductFeaturesMap.get(product)?.includes(feature) ?? false) {
			if (expiresInSecs === null) {
				delta = Number.POSITIVE_INFINITY;
			} else {
				delta = Math.max(delta, expiresInSecs);
			}
		}
	}
	return delta;
}

export function productsProvideFeature(products: ProductWithLicenceId[], feature: FeatureName) {
	return latestFeatureExpiration(products, feature) >= 0;
}
