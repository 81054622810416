/**
 * @module utils
 */
/** comment to work-around limitation of typedoc module plugin */

// Copyright 2018-2024 Enlightware GmbH, Switzerland

export async function sleep(durationSeconds: number) {
	return new Promise<void>((resolve) => { setTimeout(resolve, durationSeconds * 1000); });
}

export function promiseAny<T>(promises: PromiseLike<T>[]): PromiseLike<T> {
	return Promise.all<T>(
		promises.map((promise) =>
			promise.then((val) => {
				throw val;
			// eslint-disable-next-line @typescript-eslint/no-unsafe-return
			}, (reason) => reason)
		)
	).then((reasons) => {
		throw reasons;
	// eslint-disable-next-line @typescript-eslint/no-unsafe-return
	}, (firstResolved) => firstResolved);
}

export async function asyncFilter<T>(arr: T[], predicate: (_: T) => PromiseLike<boolean>): Promise<T[]> {
	const results = await Promise.all(arr.map(predicate));
	return arr.filter((_v, index) => results[index]);
}

type CallbackObject<CN extends string, CA> = {
	[K in CN]: null | ((_: CA) => void);
};

interface CancellableCallbackPromiseInterface extends PromiseLike<string> {
	readonly identifier: string;
	cancelCallback(): void;
}

// Note: due to a bug in Typescript, if argument callbackHolder is before testFn, compilation fails.
// Note: the executor is called twice, see
// https://stackoverflow.com/questions/53146565/constructor-of-a-custom-promise-class-is-called-twice-extending-standard-promis
// and https://v8.dev/blog/fast-async#await-under-the-hood
export class CancellableCallbackPromise<CN extends string, CA>
	extends Promise<string>
	implements CancellableCallbackPromiseInterface {
	constructor(
		public identifier: string,
		testFn: (arg: CA) => boolean,
		private callbackHolder: CallbackObject<CN, CA>,
		private callbackName: CN
	) {
		super(
			(resolve, _) => {
				callbackHolder[callbackName] = (arg: CA) => {
					if (testFn(arg)) {
						callbackHolder[callbackName] = null;
						console.debug(`CancellableCallbackPromise: Resolving ${identifier}`);
						resolve(identifier);
					}
				};
			}
		);
		console.debug(`CancellableCallbackPromise: Creating ${identifier}`);
	}
	cancelCallback() {
		this.callbackHolder[this.callbackName] = null;
		console.debug(`CancellableCallbackPromise: Cancelling ${this.identifier}`);
	}
}
// HACK to work around the way await deals with promise creation (see above)
CancellableCallbackPromise.prototype.constructor = Promise;

// we have to do this because keyof GlobalEventHandlers is too hard to parse for Typescript :-(
type CallbackAbleEvents = 'onclick';

export class CancellableEventPromise<K extends CallbackAbleEvents>
	extends Promise<string>
	implements CancellableCallbackPromiseInterface {
	constructor(
		public identifier: string,
		private target: HTMLElement,
		private eventName: K
	) {
		super(
			(resolve, _) => {
				target[eventName] = () => {
					target[eventName] = null;
					console.debug(`CancellableEventPromise: Resolving ${identifier}`);
					resolve(identifier);
				};
			}
		);
		console.debug(`CancellableEventPromise: Creating ${identifier}`);
	}
	cancelCallback() {
		this.target[this.eventName] = null;
		console.debug(`CancellableEventPromise: Cancelling ${this.identifier}`);
	}
}
// HACK to work around the way await deals with promise creation (see above)
CancellableEventPromise.prototype.constructor = Promise;

export async function waitAny(promises: CancellableCallbackPromiseInterface[]) {
	console.debug(`CancellableCallbackPromise: Waiting on ${promises.map((c) => c.identifier).join(', ')}`);
	const result = await promiseAny(promises);
	for (const promise of promises) {
		if (promise.identifier !== result) {
			promise.cancelCallback();
		}
	}
	console.debug(`CancellableCallbackPromise: Done waiting on ${promises.map((c) => c.identifier).join(', ')}`);
	return result;
}

export async function domEvent<K extends keyof GlobalEventHandlers>(event: K, element: HTMLElement) {
	return new Promise<void>(
		(resolve, _) => {
			element.addEventListener(event.substring(2), () => resolve());
		}
	);
}

export function constantPromise<T>(value: T): Promise<T> {
	// eslint-disable-next-line @typescript-eslint/require-await
	return (async () => value)();
}

export function makeSync(f: () => Promise<void>) {
	return () => { void f() };
}

export function awaitClick(element: HTMLElement, onclick = (_pos: [number, number]) => {}): Promise<[number, number]> {
	return new Promise<[number, number]>((resolve, _) => {
		element.onclick = (e: MouseEvent) => {
			element.onclick = null;
			onclick([e.clientX, e.clientY]);
			resolve([e.clientX, e.clientY]);
		}
	});
}

export function readFileAsync(file: File): Promise<string | ArrayBuffer | null> {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();

		reader.onload = () => {
			resolve(reader.result);
		};

		reader.onerror = reject;

		reader.readAsArrayBuffer(file);
	})
}

export function readFileAsTextAsync(file: File): Promise<string	| ArrayBuffer | null> {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();

		reader.onload = () => {
			resolve(reader.result);
		};

		reader.onerror = reject;

		reader.readAsText(file);
	})
}

export function readFileAsDataUrlAsync(file: File): Promise<string | ArrayBuffer | null> {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();

		reader.onload = () => {
			resolve(reader.result);
		};

		reader.onerror = reject;

		reader.readAsDataURL(file);
	})
}

export function loadImageAsync(src: string): Promise<HTMLImageElement> {
	return new Promise((resolve, reject) => {
		const image = new Image();

		image.onload = () => {
			resolve(image);
		};

		image.onerror = reject;

		image.src = src;
	})
}