/**
 * @module utils
 */
/** comment to work-around limitation of typedoc module plugin */

// Copyright 2018-2024 Enlightware GmbH, Switzerland

import { v1 as uuidv1, v4 as uuidv4, v5 as uuidv5 } from 'uuid';

export function createCurrentLocationBasedNamespace(suffix: string): string {
	return uuidv5(window.location.search + suffix, uuidv5.DNS);
}

export function createCurrentHostBasedUuid(namespace?: string): string {
	const macAddressAndTimeBasedUuid = uuidv1();
	return namespace ?
		uuidv5(macAddressAndTimeBasedUuid, namespace)
		: macAddressAndTimeBasedUuid;
}

export function createCurrentHostBasedUuidBuffer(namespace: string) {
	const buffer = new Uint8Array(16);
	const macAddressAndTimeBasedUuid = uuidv1();
	uuidv5(macAddressAndTimeBasedUuid, namespace, buffer);
	return buffer;
}

export function createRandomUuidBuffer() {
	const buffer = new Uint8Array(16);
	uuidv4(null, buffer);
	return buffer;
}
