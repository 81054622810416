/**
 * @module utils
 */
/** comment to work-around limitation of typedoc module plugin */

// Copyright 2018-2024 Enlightware GmbH, Switzerland

/**
 * Perform bit-by-bit XOR operation between two buffers.
 * If buffers differ in length, the overlapping part is XORed and the rest copied from the longest buffer.
 * @param bufferA first buffer
 * @param bufferB second buffer
 * @return the XORed buffer
 */
export function xorBuffers(bufferA: ArrayBuffer, bufferB: ArrayBuffer) {
	const totalLength = Math.max(bufferA.byteLength, bufferB.byteLength);
	const overlapLength = Math.min(bufferA.byteLength, bufferB.byteLength);
	const srcA = new Uint8Array(bufferA);
	const srcB = new Uint8Array(bufferB);
	const dest = new Uint8Array(totalLength);
	for (let i = 0; i < overlapLength; ++i) {
		dest[i] = srcA[i]! ^ srcB[i]!;
	}
	const srcLeft = bufferA.byteLength < totalLength ? srcB : srcA;
	for (let i = overlapLength; i < totalLength; ++i) {
		dest[i] = srcLeft[i]!;
	}
	return dest;
}

// buffer <-> hexadecimal conversion

// TODO find faster version of bufferToHex
export function bufferToHex(buffer: ArrayBuffer) {
	return Array
		.from(new Uint8Array(buffer))
		.map((b) => b.toString(16).padStart(2, '0'))
		.join('');
}

export function hexToBuffer(hex: string) {
	const buffer = new Uint8Array(16);
	for (let i = 0; i < 16; ++i) {
		buffer[i] = parseInt(hex.substring(i*2, (i+1)*2), 16);
	}
	return buffer;
}